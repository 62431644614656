<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Propos',

    metaInfo: { title: 'À propos' },

    extends: View,

    mixins: [
      LoadSections([
        'hero-alt',
        // 'about-our-product',
        // 'theme-features',
        'philosophie',
        'who-am-i',
        'mission',
        // 'newsletter',
        // 'pro-features',
        'info',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'about',
      },
    },
  }
</script>
